import React from 'react';

//icons
import { DiGithubBadge } from "react-icons/di";
import { MdOpenInNew } from "react-icons/md";


function Card(props){
    return(
        <div className={`card ${props.type}`}>
            <div className='card-body'>
                <a className='feature' href={props.link} target='_blank' title={props.title} rel="noopener noreferrer">
                  <img src={props.img} alt={props.title} tile={props.title}/>
                </a>
                <h2 className='title'>{props.title}</h2>
                <p>{props.info}</p>
            </div>
            <div className='links'>
              <a href={props.link}  target='_blank' rel="noopener noreferrer">View <MdOpenInNew/></a>
              <a href={props.code}  target='_blank' rel="noopener noreferrer">Code <DiGithubBadge/></a>
            </div>
        </div>);
}

export default Card;