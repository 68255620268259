import React from 'react';

function Intro(props){
    return(
        <div id='intro'>
            <h1>Carlos Aviles Buitron | Email&nbsp;Samples</h1>
            <div className='intro-copy'>
            <p>Welcome to my HTML Email visual and code samples page. Below are samples of emails deployed from several different email service providers including MailChimp, Pardot, Luminate and more. All emails are hand coded and when deployed include features such as dynamic tracking, responsive design, and marketing automation.</p>
            </div>
        </div>);
}

export default Intro;