import React, {useEffect} from 'react';

//data
import data from './data/samples.json';

//styles
import './styles/App.scss';

//components
import Intro from './components/intro/intro';
import Grid from './components/grid/grid';
import Card from './components/card/card';
import Footer from './components/footer/footer';

function App() {
  
  //state
  //const [load, setload] = useState(false);
  
  //methods
  const render_samples = (samples)=>{
      
    const render = samples.map((sample, index)=>{
      return(
        <Card 
          key={index}
          title={sample.title}
          img={sample.img}
          link={sample.link}
          code={sample.code}
          info={sample.info}
          type={sample.type}
        >
        </Card>
        );
    });
    
    return render;
    
  };
  
  //lifecycle
  useEffect(()=>{
    
  
    
  },[]);
  
  return (
    <div className="App">
      <div className="container">
      
        <Intro />
        <Grid>{render_samples(data)}</Grid>
        
      </div>
      
      <Footer/>
    </div>
  );
}

export default App;
